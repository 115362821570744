import React from 'react'
import styled from '@emotion/styled'
import { useDispatch, useSelector } from 'react-redux'
import { Currency, toMoney } from '@mobi/utils/money'
import { colors, font, radius, shadow, spacing } from '@mobi/component-library/Theme/Common'
import type { InvestmentType } from '@mobi/betslip/types'
import { setActiveInvestment } from '@mobi/betslip/Store/Workflow'
import {
  selectIsBettingDisabled,
  selectKeypadActiveValue,
} from '@mobi/betslip/Store/Workflow/selectors'
import { useRegisterInvestmentInput } from './hooks/useRegisterInvestmentInput'
import { useCaptureValueOnActiveInput } from './hooks/useCaptureValueOnActiveInput'

const enum LocalConstants {
  StackedDisplayClass = 'js-investment--stacked',
  InputActiveClass = 'js-investment__input--active',
  InputHasValueClass = 'js-investment__input--has-value',
  InputEditableClass = 'js-investment__input--editable',
  InputDisabledClass = 'js-investment__input--disabled',
}

export const Investment: React.FC<{
  itemId: string | 'multi'
  investmentType: InvestmentType
  isActive: boolean
  value: number
  isEditable: boolean
  label?: string
  display?: 'inline' | 'stacked'
  valueType?: 'money' | 'number' | 'percentage'
}> = ({
  itemId,
  investmentType,
  isActive,
  value,
  isEditable,
  label,
  display = 'inline',
  valueType = 'money',
}) => {
  const dispatch = useDispatch()
  const investmentElementRef = React.useRef<HTMLDivElement>(null)

  const activeValue = useSelector(selectKeypadActiveValue)

  const isBettingDisabled = useSelector(selectIsBettingDisabled)

  useRegisterInvestmentInput({ itemId, investmentType })
  useCaptureValueOnActiveInput({ isActive, value })

  React.useEffect(() => {
    if (isActive) investmentElementRef.current?.scrollIntoView({ block: 'center' })
  }, [isActive])

  const handleInvestmentClick = () => {
    if (!isActive) dispatch(setActiveInvestment({ investmentType, itemId }))
  }

  const hasValue = value > 0
  const valueToRender = isActive ? activeValue ?? value : value

  const isDecimalActive =
    isActive && (new Currency(activeValue || 0).value > 0 || activeValue?.includes('.'))

  const decimalPlaces = isActive && hasValue && !valueToRender.toString().includes('.') ? 0 : 2

  const wrapperClassNames = []
  if (display === 'stacked' && isEditable)
    wrapperClassNames.push(LocalConstants.StackedDisplayClass)

  const inputClassNames = getInputClassNames({
    isActive,
    hasValue,
    isDecimalActive,
    isEditable,
    isBettingDisabled,
  })

  return (
    <WrapperStyled ref={investmentElementRef} className={wrapperClassNames.join(' ')}>
      <span>{label || investmentType}</span>

      <span onClick={handleInvestmentClick} className={inputClassNames.join(' ')}>
        {(!isActive || !hasValue) && valueType === 'money' && toMoney(value, { decimalPlaces })}

        {isActive && hasValue && (
          <>
            {`$${valueToRender || 0}`}
            <span />
          </>
        )}

        {valueType === 'number' && value}

        {valueType === 'percentage' && '%'}
      </span>
    </WrapperStyled>
  )
}

// =============
// Local Helpers
// =============

interface GetInputClassNamesParams {
  isActive: boolean
  hasValue: boolean
  isDecimalActive?: boolean
  isEditable: boolean
  isBettingDisabled: boolean
}

const getInputClassNames = ({
  isActive,
  hasValue,
  isDecimalActive,
  isEditable,
  isBettingDisabled,
}: GetInputClassNamesParams): string[] => {
  const classNames: string[] = []

  if (isActive) classNames.push(LocalConstants.InputActiveClass)
  if (hasValue || (isActive && isDecimalActive)) classNames.push(LocalConstants.InputHasValueClass)
  if (isEditable) classNames.push(LocalConstants.InputEditableClass)
  if (isBettingDisabled) classNames.push(LocalConstants.InputDisabledClass)

  return classNames
}

// ======
// Styles
// ======

const WrapperStyled = styled.div({
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  gap: spacing.smx2,
  scrollMargin: spacing.sm,
  minHeight: '2.4rem',

  // Label
  '> span:first-of-type': {
    fontSize: font.size.md.fontSize,
    letterSpacing: font.size.md.letterSpacing,
    lineHeight: font.size.md.lineHeight,
    fontWeight: font.weight.regular,
    color: colors.black,
  },

  // Input Box
  '> span:last-of-type': {
    boxSizing: 'border-box',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    width: '100%',
    maxWidth: '12rem',

    textAlign: 'end',
    color: colors.neutral[700],
    fontSize: font.size.md.fontSize,
    letterSpacing: font.size.md.letterSpacing,
    lineHeight: font.size.md.lineHeight,
    fontWeight: font.weight.regular,

    [`&.${LocalConstants.InputEditableClass}`]: {
      padding: spacing.sm + ' ' + spacing.md,
      border: '1px solid ' + colors.neutral[300],
      borderRadius: radius.md,
      backgroundColor: colors.white,
      boxShadow: shadow.xs,
      touchAction: 'manipulation',
    },

    [`&.${LocalConstants.InputActiveClass}`]: {
      outline: `${colors.lavender[50]} solid 0.4rem`,
      borderColor: colors.lavender[200],
      fontWeight: font.weight.medium,
    },

    [`&.${LocalConstants.InputHasValueClass}`]: {
      fontWeight: font.weight.medium,
      color: colors.black,
    },

    [`&.${LocalConstants.InputDisabledClass}`]: {
      background: colors.surface[100],
      color: colors.neutral[400],
      fontWeight: font.weight.regular,
      border: `1px solid ${colors.neutral[300]}`,
      boxShadow: shadow.xs,
      pointerEvents: 'none',
      cursor: 'not-allowed',
    },

    '> span': {
      display: 'inline-block',
      width: '2px',
      height: font.size.md.lineHeight,
      background: colors.lavender[200],
      opacity: 0,
      animation: 'blinking 1s infinite',

      '@keyframes blinking': {
        '0%': { opacity: 0 },
        '50%': { opacity: 1 },
        '100%': { opacity: 0 },
      },
    },
  },

  [`&.${LocalConstants.StackedDisplayClass}`]: {
    flex: 1,
    flexDirection: 'column',
    alignItems: 'start',

    '> span:last-of-type': {
      maxWidth: 'unset',
    },
  },
})
