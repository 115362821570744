import React from 'react'
import { type PayIdDetails } from '@mobi/api-types/src/account/betAccount'
import { addToast, ToastContent } from '@mobi/component-library/Feedback/Toast'
import { track } from '../../../Utils/analytics'
import { addCrumb } from '@mobi/utils'
import { reportErrorIfNeeded } from '../../../Utils/sentry'

export const FeedbackPayIdStatusChange = (
  accountNumber: number,
  status: PayIdDetails['CreationStatus']
) => {
  const timeStamp = new Date().toISOString()

  switch (status) {
    case 'active':
      addCrumb('deposit', 'PayId zepto active')
      track('deposit_payid_active', {
        accountNumber,
        timeStamp,
      })
      addToast({
        id: 'payid-activation-success',
        isDismissable: true,
        content: <ToastContent title='Your PayID has been generated.' icon='success' />,
      })
      break
    case 'failed':
      reportErrorIfNeeded({ message: 'PayId zepto failed' })
      track('deposit_payid_failed', {
        accountNumber,
        timeStamp,
      })
      addToast({
        id: 'payid-activation-failure',
        isDismissable: true,
        content: (
          <ToastContent
            title='We’re unable to generate your PayID.'
            subtitle='Please call 1300 36 36 88 to resolve the matter.'
            icon='error'
          />
        ),
      })
      break
    case 'deregistered':
      reportErrorIfNeeded({ message: 'PayId zepto deregistered' })
      track('deposit_payid_deregistered', {
        accountNumber,
        timeStamp,
      })
      break
    case 'disabled':
      reportErrorIfNeeded({ message: 'PayId zepto disabled' })
      track('deposit_payid_disabled', {
        accountNumber,
        timeStamp,
      })
      break
  }
}
