import React from 'react'
import styled from '@emotion/styled'
import { colors, font, spacing } from '@mobi/component-library/Theme/Common'
import { toMoney } from '@mobi/utils/money'
import type { BetSlipItem, FobSelection } from '@mobi/betslip/types'
import { useAppSelector } from '@mobi/betslip/Store/hooks'
import { calculateEstReturn, calculateTotalStake } from '@mobi/betslip/helpers/calculator'
import {
  getBetsToPlace,
  getBetsInMulti,
  isValidMulti,
  isFatalErrorType,
} from '@mobi/betslip/helpers/state'
import { BetSlipContext } from '@mobi/betslip/context'
import { getPriceChange, PriceChange } from '@mobi/betslip/helpers/getPriceChange'
import { ErrorMessage } from './Components/Messages/ErrorMessage'
import { InvalidBetsMessage } from './Components/Messages/InvalidBetsMessage'
import { ReviewChangesMessage } from './Components/Messages/ReviewChangesMessage'
import { BetsPlacedSuccessToast } from './Components/BetsPlacedSuccessToast'

export const SummarySection: React.FC = () => {
  const workflowStatus = useAppSelector(state => state.betslip.workflow.currentStatus)
  const apiErrorMessage = useAppSelector(state => state.betslip.workflow.apiErrorMessage)
  const items = useAppSelector(state => state.betslip.bets.items)
  const multiBetError = useAppSelector(state => state.betslip.bets.multiBetError)
  const multiInvestment = useAppSelector(state => state.betslip.bets.multiInvestment)
  const multiReceipt = useAppSelector(state => state.betslip.bets.multiReceipt)

  const toastEmitter = React.useContext(BetSlipContext)?.toastEmitter

  React.useEffect(() => {
    if (workflowStatus !== 'bets-placed' || !toastEmitter) return
    toastEmitter.emit('addToast', {
      id: 'success',
      content: <BetsPlacedSuccessToast />,
      isDismissable: true,
      timeoutMs: Infinity,
    })
    return () => {
      toastEmitter.emit('removeToast', { id: 'success' })
    }
  }, [workflowStatus, toastEmitter])

  const isValidMultiPresent =
    !multiReceipt && isValidMulti(multiInvestment, multiBetError, getBetsInMulti(items))

  const singlesLeftToPlace = getBetsToPlace(items).length

  const hasSeriousMultiError =
    !!multiBetError &&
    (isFatalErrorType(multiBetError.betErrorType) ||
      multiBetError.betErrorType === 'BetPlacementFault' ||
      multiBetError.betErrorType === 'DuplicateBonusBet' ||
      multiBetError.betErrorType === 'Unspecified')

  const hasMultiLeftToPlace = !multiReceipt && !hasSeriousMultiError && isValidMultiPresent
  const hasBetsLeftToPlace = singlesLeftToPlace > 0 || hasMultiLeftToPlace

  const hasErrors =
    !!multiBetError ||
    items.some(
      x =>
        x.betErrorType !== undefined ||
        !!x.multiBetLegError ||
        isPriceChangeDown(x as unknown as BetSlipItem)
    )
  const hasPlacedBets = items.some(x => !!x.receipt) || !!multiReceipt

  const multiItems = getBetsInMulti(items)
  const placeableItems = getBetsToPlace(items)
  const placeableMultis =
    !multiReceipt && isValidMulti(multiInvestment, null, multiItems) ? multiItems : []
  const estimatedReturn = calculateEstReturn(placeableItems, placeableMultis, multiInvestment)

  const displayValue = Number.isFinite(estimatedReturn) && estimatedReturn > 0
  const hasProposed = workflowStatus === 'proposed'

  if (workflowStatus === 'bets-placed') return null

  return (
    <WrapperStyled>
      {(hasProposed || !!apiErrorMessage) && (
        // TODO: Determine where error messaging will go, refer to logic
        <span style={{ background: 'white ', display: 'none' }}>
          {!!apiErrorMessage && <ErrorMessage errorMessage={apiErrorMessage} />}

          {!hasBetsLeftToPlace && !hasPlacedBets && <InvalidBetsMessage />}

          {hasBetsLeftToPlace && hasErrors && (
            <ReviewChangesMessage includeCancellationWarning={hasProposed} />
          )}
        </span>
      )}

      <div>
        <span>Total Stake</span>{' '}
        <span>{toMoney(calculateTotalStake(items, multiInvestment, multiBetError))}</span>
      </div>

      <div>
        <span>Potential Returns</span>{' '}
        <span>{displayValue ? toMoney(estimatedReturn) : 'N/A'}</span>
      </div>
    </WrapperStyled>
  )
}

// =============
// Local Helpers
// =============

function isFobSelection(selection: BetSlipItem['selection']): selection is FobSelection {
  return !!(selection && 'winPrice' in selection)
}

const isPriceChangeDown = ({ selection }: BetSlipItem): boolean => {
  if (!selection || !isFobSelection(selection)) {
    return false
  }
  const winPriceChange = getPriceChange(selection.winPriceLastSeen, selection.winPrice)
  const placePriceChange: PriceChange = selection.placePrice
    ? getPriceChange(selection.placePriceLastSeen, selection.placePrice)
    : 'NoChange'
  return winPriceChange === 'Down' || placePriceChange === 'Down'
}

// ======
// Styles
// ======

const WrapperStyled = styled.div({
  display: 'flex',
  flexDirection: 'column',
  gap: spacing.sm,

  '> div': {
    display: 'flex',
    justifyContent: 'space-between',
    fontSize: font.size.lg.fontSize,
    letterSpacing: font.size.lg.letterSpacing,
    lineHeight: font.size.lg.lineHeight,
    fontWeight: font.weight.regular,
    color: colors.white,

    '> span:last-of-type': {
      fontWeight: font.weight.semibold,
    },
  },
})
