import React from 'react'
import type { PriceChange } from '@mobi/betslip/helpers/getPriceChange'

export const MultiReturn: React.FC<{
  price: number | null
  priceChange?: PriceChange
}> = ({ price }) => {
  if (!price) return <span>N/A</span>

  // priceChange={priceChange}
  return <div>{price}</div>
}
