import type { BetSlipBetsState } from '@mobi/betslip/Store/Bets'
import type { RequestType } from '@mobi/betslip/types'
import { buildBetSlipRequest } from '@mobi/betslip/helpers/requestBuilder'
import { getBetsInMulti } from '@mobi/betslip/helpers/state'
import {
  betslipPropose as propose,
  betslipCommit as commit,
  betslipRefresh as refresh,
} from '@core/Data/betting'

export async function postAllBetsToServer(queryType: RequestType, state: BetSlipBetsState) {
  const multiItems = !state.multiReceipt ? getBetsInMulti(state.items) : undefined

  const request = buildBetSlipRequest(
    queryType,
    state.items,
    multiItems,
    state.multiInvestment,
    state.multiBetError
  )

  if (queryType === 'propose') {
    const response = await propose(request)
    return response
  }

  if (queryType === 'confirm') {
    const response = await commit(request)
    return response
  }

  const response = await refresh(request)
  return response
}
