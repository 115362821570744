export const MIN_LEGS_IN_MULTI = 2

export const MAX_LEGS_IN_RACE_MULTI = 10
export const MAX_LEGS_IN_SPORT_MULTI = 15

export const MAX_LEGS_FOR_MULTI_FORMULA = 6

export const MULTI_FORMULA_COMBINATIONS = {
  f1: { legs2: 2, legs3: 3, legs4: 4, legs5: 5, legs6: 6 },
  f2: { legs3: 3, legs4: 6, legs5: 10, legs6: 15 },
  f3: { legs4: 4, legs5: 10, legs6: 20 },
  f4: { legs5: 5, legs6: 15 },
  f5: { legs6: 6 },
} as const

export const MIN_INVESTMENT_VALUE = 0.5
export const MAX_INVESTMENT_VALUE = 99_999.99
