import React from 'react'
import { InlineAlert } from '@mobi/betslip/Components/Common/InlineAlert'
import { useAppSelector } from '@mobi/betslip/Store/hooks'

export const MultiMessage: React.FC = () => {
  const multiReceipt = useAppSelector(state => state.betslip.bets.multiReceipt)

  if (multiReceipt) return <InlineAlert type='success' text='Your bet has been placed' />

  return null
}
