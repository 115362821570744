import React from 'react'
import styled from '@emotion/styled'
import { colors, font, spacing } from '@mobi/component-library/Theme/Common'
import { Accordion } from '@mobi/component-library/Common/V2/Accordion'
import type { BetSlipItem } from '@mobi/betslip/types'
import { useAppSelector } from '@mobi/betslip/Store/hooks'
import { PriceChange, getPriceChange } from '@mobi/betslip/helpers/getPriceChange'
import { selectActiveInvestment } from '@mobi/betslip/Store/Workflow/selectors'
import {
  calculateMultiProjectedPay,
  calculateMultiReturn,
  calculateLastSeenMultiReturn,
} from '@mobi/betslip/helpers/calculator'
import {
  getBetsInMulti,
  hasTooFewMultiLegs,
  hasTooManyMultiLegs,
  isValidMulti,
  isValidMultiInvestmentForLegs,
} from '@mobi/betslip/helpers/state'
import { MIN_LEGS_IN_MULTI } from '@mobi/betslip/helpers/constants'
import { Investment, InvestmentsWrapperStyled } from '../Common/Investment'
import { BetCard } from '../Common/BetCard'
import { MultiMessage, MultiItem, MultiReturn } from './Components'

export const Multi: React.FC<{ items: BetSlipItem[] }> = ({ items }) => {
  const workflowStatus = useAppSelector(state => state.betslip.workflow.currentStatus)
  const isBusy = useAppSelector(state => state.betslip.workflow.isBusy)

  const receipt = useAppSelector(state => state.betslip.bets.multiReceipt)
  const multiBetError = useAppSelector(state => state.betslip.bets.multiBetError)
  const multiInvestment = useAppSelector(state => state.betslip.bets.multiInvestment)

  const activeInvestment = useAppSelector(selectActiveInvestment)

  const isEditable = workflowStatus === 'ready'
  const isDisabled = !isEditable || isBusy || receipt != null

  const selectedMultiItems = getBetsInMulti(items)

  const isMultiValid = isValidMulti(multiInvestment, multiBetError, selectedMultiItems)
  const isMultiInvestmentValid = isValidMultiInvestmentForLegs(
    multiInvestment,
    selectedMultiItems.length
  )

  if (!isEditable && !isMultiValid && !multiBetError) return null

  const hasEnoughSelections = selectedMultiItems.length >= MIN_LEGS_IN_MULTI
  const hasTooManyLegs = hasTooManyMultiLegs(selectedMultiItems)

  const multiReturn = calculateMultiReturn(items, { shouldRound: true })
  const multiReturnLastSeen = calculateLastSeenMultiReturn(items, { shouldRound: true })
  const multiReturnPriceChange: PriceChange = multiReturnLastSeen
    ? getPriceChange(multiReturnLastSeen, multiReturn)
    : 'NoChange'

  const isValidNumberOfLegs = !hasTooFewMultiLegs(selectedMultiItems) && !hasTooManyLegs

  return (
    <Accordion
      title='Multi (1)'
      onToggle={() => null}
      shouldStartExpanded
      shouldUseDefaultTheme={false}
    >
      <BetCard.Container topComponent={<MultiMessage />}>
        <MultiContentStyled data-testid='Multi'>
          <div data-testid='Multi.Info'>
            <div>
              <span>{selectedMultiItems.length} Leg Multi</span>
              <span>
                <MultiReturn price={multiReturn} priceChange={multiReturnPriceChange} />
              </span>
            </div>

            <div data-testid='Multi.Info.Error'>
              {!hasEnoughSelections && (
                <div>Error: {`${MIN_LEGS_IN_MULTI} legs minimum for Multi`}</div>
              )}

              {!multiBetError?.errorMessage && hasTooManyLegs && (
                <div>
                  Error: Exceeded number of allowed legs for Multi bet. Please amend your bet.
                </div>
              )}

              {multiBetError?.errorMessage && (
                <div>
                  Error:{' '}
                  {multiBetError.errorMessage.indexOf('Multibet') > -1 &&
                  multiBetError.errorMessage.indexOf('must contain no more than') > -1
                    ? 'Exceeded number of allowed legs for Multi bet. Please amend your bet.'
                    : multiBetError.errorMessage}
                </div>
              )}

              {multiInvestment.f1 > 0 && !isMultiInvestmentValid && (
                <div>
                  Warning: Invalid formula investment, &quot;Singles only&quot; is not a valid bet
                  type.
                </div>
              )}
            </div>
          </div>

          <div data-testid='Multi.Selections'>
            {items.map(item => {
              if (workflowStatus !== 'ready' && !item.isInMulti) return null
              return <MultiItem key={item.id || ''} item={item} />
            })}
          </div>

          <InvestmentsWrapperStyled data-testid='Multi.Investment'>
            <Investment
              isActive={activeInvestment?.investmentType === 'Multi'}
              itemId=''
              investmentType='Multi'
              label='Stake'
              isEditable={!isDisabled && isValidNumberOfLegs}
              value={multiInvestment.value}
              // isBonusBet={multiInvestment.isBonusBet}
            />

            <Investment
              isActive={activeInvestment?.investmentType === 'MultiReverseStake'}
              itemId=''
              investmentType='MultiReverseStake'
              label='Potential Payout'
              isEditable={!isDisabled && isValidNumberOfLegs}
              value={calculateMultiProjectedPay(selectedMultiItems, multiInvestment)}
            />
          </InvestmentsWrapperStyled>
        </MultiContentStyled>
      </BetCard.Container>
    </Accordion>
  )
}

// ======
// Styles
// ======

const MultiContentStyled = styled.div({
  display: 'flex',
  flexDirection: 'column',
  fontFamily: font.family.primary,

  // Multi Info
  '> div:nth-of-type(1)': {
    '> div:first-of-type': {
      display: 'flex',
      justifyContent: 'space-between',
      paddingBottom: spacing.smx1,
      borderBottom: `0.5px solid ${colors.neutral[200]}`,
      fontSize: font.size.lg.fontSize,
      letterSpacing: font.size.lg.letterSpacing,
      lineHeight: font.size.lg.lineHeight,
      fontWeight: font.weight.medium,
      color: colors.black,
    },
  },

  // Selections
  '> div:nth-of-type(2)': {
    display: 'flex',
    flexDirection: 'column',
    gap: spacing.sm,
    paddingTop: spacing.sm,
    paddingBottom: spacing.sm,
  },
})
