import React from 'react'
import styled from '@emotion/styled'
import { Drawer } from '@mobi/component-library/Overlays/Drawer'
import { BetSlip } from '@mobi/betslip/App'
import { setHostHelpers } from '@mobi/betslip/helpers/hostHelpers'
import { useFeature } from '@core/Utils/hooks'
import { startListening } from '@core/Store/listenerMiddleware'
import { useAppSelector } from '@core/Store/hooks'
import { HeaderPanel } from './Components/HeaderPanel'
import { dismissBetSlip, postAllBetsToServer } from './helpers'
import { AppRoutes } from '@core/AppRoutes'
import { TakeABreakStatus } from './Components/TakeABreakStatus'
import { useTakeABreak } from '../Account/TakeABreak/Hooks'
import { useRealtimeFobPriceChanges, useRefreshBalanceOnBetsPlaced } from './hooks'

setHostHelpers({
  postAllBetsToServer,
  dismissBetSlip,
  redux: { startListening },
  hostRoutes: {
    Meetings: AppRoutes.Meetings,
    Sports: AppRoutes.Sports,
  },
})

export const BetSlipNew: React.FC = () => {
  const isNewBetSlipDesignActive = useFeature('NEW_BET_SLIP_DESIGN')
  const isOpen = useAppSelector(state => state.appHeader.isBetSlipNewOpen)

  const getTakeABreakDetails = useTakeABreak()
  const takeABreakIsActive = getTakeABreakDetails.isSuccess && getTakeABreakDetails.data?.inProgress

  useRealtimeFobPriceChanges(isNewBetSlipDesignActive)
  useRefreshBalanceOnBetsPlaced()

  if (!isNewBetSlipDesignActive) return null

  return (
    <Drawer isOpen={isOpen} shouldUseBodyScrollLock={false}>
      {takeABreakIsActive && <TakeABreakStatus />}

      <HeaderPanel />

      <BetSlipParentWrapperStyled>
        <BetSlip />
      </BetSlipParentWrapperStyled>
    </Drawer>
  )
}

// ======
// Styles
// ======

const BetSlipParentWrapperStyled = styled.div({
  flex: 1,
  display: 'flex',
  flexDirection: 'column',
  position: 'relative',
  overflow: 'hidden',
})
