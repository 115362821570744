import React from 'react'
import type { BetSlipItem } from '@mobi/betslip/types'
import { isFatalErrorType } from '@mobi/betslip/helpers/state'
import { isToteSelection } from '@mobi/betslip/helpers/typeGuards'
import { FobInvestment, ToteInvestment } from './Components'

export const BetInvestment: React.FC<{
  item: BetSlipItem
  isEditable: boolean
}> = ({ item, isEditable }) => {
  if (!item.selection || isFatalErrorType(item.betErrorType)) return null

  if (!isToteSelection(item.selection)) {
    return <FobInvestment item={item} isEditable={!item.receipt && isEditable} />
  }

  if (isToteSelection(item.selection)) {
    return <ToteInvestment item={item} isEditable={!item.receipt && isEditable} />
  }

  return null
}
