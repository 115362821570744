import React from 'react'
import styled from '@emotion/styled'
import { useDispatch } from 'react-redux'
import { colors, spacing } from '@mobi/component-library/Theme/Common'
import { Toasts } from '@mobi/component-library/Feedback/Toast'
import { createToastsEventEmitter } from '@mobi/component-library/Feedback/Toast/helpers/events'
import { useBodyScrollLock } from '@mobi/utils/hooks/useBodyScrollLock'
import { Tabs, TabButton } from '@mobi/component-library/Navigation/Tabs'
import { useAppSelector } from './Store/hooks'
import { selectBetSlipItems } from './Store/Bets/selectors'
import { type BetSlipWorkflowState, setActiveInvestment } from './Store/Workflow'
import { isSingleAllowedInMulti } from './helpers/state'
import { MIN_LEGS_IN_MULTI } from './helpers/constants'
import { Singles, Multi, Footer, EmptyState } from './Components'
import { BetSlipContext } from './context'
import { refreshAllBets } from './Store/Workflow/asyncActions'
import { selectIsHostUserLoggedIn, selectWorkflowStatus } from './Store/Workflow/selectors'

const enum LocalConstants {
  ScrollableClass = 'js-betslip__scrollable',
  FooterClass = 'js-betslip__footer',
}

export const BetSlip: React.FC = () => {
  const dispatch = useDispatch()

  const [toastEmitter] = React.useState(createToastsEventEmitter)

  const registeredInvestmentInputsRef = React.useRef<
    NonNullable<BetSlipWorkflowState['activeInvestment']>[]
  >([])

  const items = useAppSelector(selectBetSlipItems)

  const workflowStatus = useAppSelector(selectWorkflowStatus)
  const isLoggedIn = useAppSelector(selectIsHostUserLoggedIn)

  const hasItems = items.length > 0

  // TODO: Add context so only mobi applies scrolllock
  const scrollableContinerRef = useBodyScrollLock(hasItems)

  React.useEffect(() => {
    // TODO: Re-implement subscription for login/out events
    // * On workflow change to ready, always refresh if logged in (covers mount)
    // const loggedInSubscription = subscribe(isLoggedIn => {
    //     isLoggedIn ? RefreshBetslip() : RemoveBonusBetsFromBetslip()
    //   })

    if (workflowStatus !== 'ready' || !isLoggedIn) return

    dispatch(refreshAllBets())

    return () => {
      dispatch(setActiveInvestment(null))
    }
  }, [dispatch, workflowStatus, isLoggedIn])

  if (!hasItems) {
    return (
      <BetslipContainerStyled>
        <EmptyState />
      </BetslipContainerStyled>
    )
  }

  const multiItems = items.filter(isSingleAllowedInMulti)
  const hasEnoughMultiItems = multiItems.length >= MIN_LEGS_IN_MULTI

  return (
    <BetSlipContext.Provider value={{ registeredInvestmentInputsRef, toastEmitter }}>
      <BetslipContainerStyled>
        <div>
          <Tabs>
            <TabButton
              name={`Singles (${items.length})`}
              onClick={() => null}
              isSelected
              shouldFillSpace={false}
            />

            {hasEnoughMultiItems && (
              <TabButton
                name='Multi (1)'
                onClick={() => null}
                isSelected={false}
                shouldFillSpace={false}
              />
            )}
          </Tabs>
        </div>

        <div ref={scrollableContinerRef} className={LocalConstants.ScrollableClass}>
          <Singles items={items} />

          {hasEnoughMultiItems && <Multi items={multiItems} />}

          <Toasts toastEmitter={toastEmitter} isRelativeToParent />
        </div>

        <div className={LocalConstants.FooterClass}>
          <Footer />
        </div>
      </BetslipContainerStyled>
    </BetSlipContext.Provider>
  )
}

// ======
// Styles
// ======

const BetslipContainerStyled = styled.div({
  position: 'absolute',
  top: 0,
  bottom: 0,
  left: 0,
  right: 0,
  display: 'flex',
  flexDirection: 'column',
  backgroundColor: colors.surface[100],

  ['.' + LocalConstants.ScrollableClass]: {
    flex: 1,
    position: 'relative',
    display: 'flex',
    flexDirection: 'column',
    paddingBottom: spacing.md,
    overflowY: 'auto',
    overflowX: 'hidden',
    scrollBehavior: 'smooth',
    overscrollBehavior: 'contain',
  },

  ['.' + LocalConstants.FooterClass]: {
    marginTop: 'auto',
  },
})
