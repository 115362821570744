import { Observable, IDisposable } from 'rx'
import { FeatureFlags } from '@mobi/settings'
import { state$ as userAccountState$ } from './userAccountDriver'
import { state$ as featureState$ } from '../LaunchDarklyFeatures/driver'
import { fetchAccountBalanceAsync } from './async-signals'
import {
  subscribe as subscribeToIot,
  unsubscribe as unsubscribeFromIot,
  isAccountBalanceChangedPushEvent,
  isPayIdStatusChangedPushEvent,
} from '../PushData'
import { getAccountTopic } from '../PushData/topics'
import {
  FeedbackBalanceDepositChange,
  FeedbackPayIdStatusChange,
  queryKeys,
} from '@mobi/account/Areas/Deposit/Utils'
import { queryClient } from '@core/Data/ReactQuery'

let pushDataSubscription: IDisposable | null = null

export const register = (): void => {
  let accountNumberLoggedIn: number | null = null

  Observable.combineLatest(
    userAccountState$,
    featureState$,
    ({ isLoggedIn, accountNumber }, featureStateRecord) => ({
      isLoggedIn,
      accountNumber,
      isAccountPushFeatureActive: featureStateRecord.features.get(
        FeatureFlags.PUSHDATA_USERINFO.key
      ) as boolean,
    })
  )
    .distinctUntilChanged()
    .subscribe(managePushDataSubscription)

  function managePushDataSubscription({
    isLoggedIn,
    accountNumber,
    isAccountPushFeatureActive,
  }: {
    isLoggedIn: boolean | null
    accountNumber: number | null
    isAccountPushFeatureActive: boolean
  }) {
    // Do initial fetch and set up subscription
    if (isAccountPushFeatureActive && isLoggedIn && !pushDataSubscription) {
      subscribe(accountNumber as number)
    }
    // Unsubscribe and dispose of subscription
    if (!!pushDataSubscription && (!isAccountPushFeatureActive || !isLoggedIn)) {
      unsubscribe()
    }
  }

  function subscribe(accountNumber: number) {
    accountNumberLoggedIn = accountNumber
    const pushEventSubscription = subscribeToIot([getAccountTopic(accountNumber)])
    pushDataSubscription = pushEventSubscription.subscribe(({ payload }) => {
      if (!payload) return

      if (isAccountBalanceChangedPushEvent(payload)) {
        fetchAccountBalanceAsync().then(() => FeedbackBalanceDepositChange())
      }
      if (isPayIdStatusChangedPushEvent(payload)) {
        FeedbackPayIdStatusChange(accountNumber, payload.status)
        /*
         * The PayID is pulled from the Bet Account Details request.
         * When the backend notifies us of a status change, we need to invalidate the cache to ensure the PayID is updated.
         */
        queryClient.invalidateQueries({
          queryKey: [queryKeys.depositBetAccount],
        })
      }
    })
  }

  function unsubscribe() {
    if (accountNumberLoggedIn === null) {
      return
    }

    unsubscribeFromIot([getAccountTopic(accountNumberLoggedIn)])
    if (!!pushDataSubscription && pushDataSubscription.dispose) {
      pushDataSubscription.dispose()
      pushDataSubscription = null
    }
  }
}
