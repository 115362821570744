import { TypedStartListening } from '@reduxjs/toolkit'
import type { BetSlipBetsState } from '../Store/Bets'
import type { BetSlipResponse } from '../types'
import type { AppState } from '../Store'

let hostHelpers: HostHelpers

/** Set host functions in module scope (NOT inside react) */
export function setHostHelpers(initHelpers: HostHelpers) {
  hostHelpers = initHelpers
}

export function getHostHelpers(): HostHelpers {
  return hostHelpers
}

// =====
// Types
// =====

export type HostHelpers = {
  redux: { startListening: TypedStartListening<AppState> }
  postAllBetsToServer: (
    queryType: 'propose' | 'confirm' | 'refresh',
    state: BetSlipBetsState
  ) => Promise<BetSlipResponse[]>

  /** Primarily used in MOBI */
  dismissBetSlip?(): void

  hostRoutes: {
    Sports: string
    Meetings: string
  }
}
