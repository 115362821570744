import { createSlice, type PayloadAction } from '@reduxjs/toolkit'
import { Currency } from '@mobi/utils/money'
import type { BetSlipErrorResponse, InvestmentType } from '@mobi/betslip/types'
import { mapErrorResponseCodeToType } from '@mobi/betslip/Store/Bets/helpers/apiResponse/errors'
import { determineValueFromKeypad } from './helpers/determineValueFromKeypad'
import { refreshAllBets, confirmAllBets, proposeAllBets } from './asyncActions'
import { MAX_INVESTMENT_VALUE } from '@mobi/betslip/helpers/constants'

const initialState: BetSlipWorkflowState = {
  activeInvestment: null,
  keypadActiveValue: null,
  isBusy: false,
  currentStatus: 'ready',
  apiErrorMessage: null,
  isBettingDisabled: false,
  isHostUserLoggedIn: false,
}

const workflowSlice = createSlice({
  name: 'betslip/workflow',
  initialState,
  reducers: {
    setActiveInvestment(state, { payload }: PayloadAction<ActiveInvestment>) {
      state.keypadActiveValue = null
      state.activeInvestment = payload
    },

    setKeypadActiveValue(
      state,
      { payload: { keypadInput, isInitValue } }: PayloadAction<SetKeypadActiveValueArgs>
    ) {
      if (isInitValue) {
        const stringValue = new Currency(keypadInput.value).toString()
        const [dollars, cents] = stringValue.split('.')
        state.keypadActiveValue = new Currency(cents).value > 0 ? stringValue : dollars
        return
      }
      const currentValue = state.keypadActiveValue
      const newValue = determineValueFromKeypad({ keypadInput, currentValue })
      if (new Currency(newValue).value > MAX_INVESTMENT_VALUE) return
      state.keypadActiveValue = newValue
    },

    setWorkflowStatusToReady(state) {
      state.currentStatus = 'ready'
    },

    setBettingDisabledStatus(state, { payload }: PayloadAction<boolean>) {
      if (payload) state.currentStatus = 'ready'

      state.isBettingDisabled = payload
    },

    setBetSlipIsUserLoggedInSync(state, { payload: isLoggedIn }: PayloadAction<boolean>) {
      state.isHostUserLoggedIn = isLoggedIn
    },
  },

  extraReducers: builder => {
    // ============
    // Propose Bets
    // ============
    builder.addCase(proposeAllBets.pending, state => {
      state.isBusy = true
      state.apiErrorMessage = null
      state.activeInvestment = null
    })

    builder.addCase(proposeAllBets.fulfilled, state => {
      state.isBusy = false
      state.currentStatus = 'proposed'
    })

    builder.addCase(proposeAllBets.rejected, (state, action) => {
      state.isBusy = false
      state.currentStatus = 'ready'

      const errorResponse = action.payload as BetSlipErrorResponse
      if (mapErrorResponseCodeToType(errorResponse.code) === 'Unauthorized') {
        return
      }
      state.apiErrorMessage =
        errorResponse.response && errorResponse.response.message
          ? errorResponse.response.message
          : 'Unable to verify bet(s) - Please try again'
    })

    // ============
    // Confirm Bets
    // ============
    builder.addCase(confirmAllBets.pending, state => {
      state.isBusy = true
      state.apiErrorMessage = null
    })

    builder.addCase(confirmAllBets.fulfilled, state => {
      state.isBusy = false
      state.currentStatus = 'bets-placed'
    })

    // TODO: Add confirmAllBets.rejected case

    // ============
    // Refresh Bets
    // ============
    builder.addCase(refreshAllBets.pending, state => {
      state.isBusy = true
      state.apiErrorMessage = null
    })

    builder.addCase(refreshAllBets.fulfilled, state => {
      state.isBusy = false
    })

    builder.addCase(refreshAllBets.rejected, (state, action) => {
      state.isBusy = false
      state.currentStatus = 'ready'

      const errorResponse = action.payload as BetSlipErrorResponse
      if (mapErrorResponseCodeToType(errorResponse.code) === 'Unauthorized') {
        return
      }
      state.apiErrorMessage =
        errorResponse.response && errorResponse.response.message
          ? errorResponse.response.message
          : 'Unable to verify bet(s) - Please try again'
    })
  },
})

export const {
  setActiveInvestment,
  setKeypadActiveValue,
  setWorkflowStatusToReady,
  setBettingDisabledStatus,
  setBetSlipIsUserLoggedInSync,
} = workflowSlice.actions

export default workflowSlice.reducer

// =====
// Types
// =====

export type BetSlipWorkflowState = {
  activeInvestment: ActiveInvestment
  keypadActiveValue: string | null

  isBusy: boolean
  currentStatus: WorkflowStep
  apiErrorMessage: string | null
  isBettingDisabled: boolean

  isHostUserLoggedIn: boolean
}

export type KeypadInput =
  | { type: 'denomation'; value: number }
  | { type: 'numeric'; value: string }
  | { type: 'action'; value: 'clear' | 'del' | '.' }

type WorkflowStep = 'ready' | 'proposed' | 'bets-placed'
type ActiveInvestment = { itemId: string; investmentType: InvestmentType } | null

type SetKeypadActiveValueArgs = { keypadInput: KeypadInput; isInitValue?: boolean }
