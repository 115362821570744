import Decimal from 'decimal.js'
import { Currency, roundEstimateDown } from '@mobi/utils/money'
import { isToteSelection, isStartingPriceSelection } from './typeGuards'
import {
  getBetsToPlace,
  hasTooManyMultiLegs,
  hasTooFewMultiLegs,
  getBetsInMulti,
  isValidMulti,
  isFatalErrorType,
} from './state'
import {
  MIN_LEGS_IN_MULTI,
  MAX_LEGS_FOR_MULTI_FORMULA,
  MULTI_FORMULA_COMBINATIONS,
} from './constants'
import type {
  MultiInvestmentKey,
  FobSelection,
  FobPropositionSelection,
  RacingBetType,
  BetSlipItem,
  BettingType,
  BetLegType,
  MultiInvestment,
  BetSpecialOffer,
} from '../types'
import type { BetSlipBetsState } from '../Store/Bets'

export function calculateBetCostSingleItem(betslipItem: BetSlipItem): number {
  const {
    bettingType,
    isEachWay,
    investment: { win, place },
  } = betslipItem
  if (isToteSelection(betslipItem.selection)) {
    return calculateBetCost(
      bettingType,
      win.value,
      place.value,
      isEachWay,
      betslipItem.selection.betType,
      betslipItem.numberOfCombinations
    )
  }
  return calculateBetCost(bettingType, win.value, place.value, isEachWay)
}

// TODO: use central location
function calculateBetCost(
  bettingType: BettingType | null,
  winInvestment: number,
  placeInvestment: number,
  isEachWay: boolean,
  betType?: RacingBetType,
  numberOfCombinations: number = 1
): number {
  switch (bettingType) {
    case 'fixed-odds-sports': {
      return winInvestment
    }
    case 'fixed-odds-racing': {
      return isEachWay
        ? new Currency(winInvestment).multiply(2).value
        : new Currency(winInvestment).add(placeInvestment).value
    }
    case 'tote-racing': {
      switch (betType) {
        case 'Win & Place': {
          const totalInvestment = new Currency(winInvestment).add(placeInvestment)
          return new Currency(totalInvestment).multiply(numberOfCombinations).value
        }
        case 'Exacta':
        case 'Quinella':
        case 'Trifecta':
        case 'First 4':
        case 'Double':
        case 'Quaddie': {
          return winInvestment
        }
        case 'All Up': {
          return new Currency(winInvestment).multiply(numberOfCombinations).value
        }
        default: {
          return 0
        }
      }
    }
    case 'mystery-quick-pick':
    case 'tote-sports-tipping': {
      return new Currency(winInvestment).multiply(numberOfCombinations).value
    }
    case 'favourite-numbers': {
      return new Currency(0.5).multiply(numberOfCombinations).value
    }
    default:
      return 0
  }
}

export const calculateTotalStake = (
  betslipItems: BetSlipItem[],
  multiInvestment: MultiInvestment,
  multiError: BetSlipBetsState['multiBetError'] | null
) => {
  const combinedSingleCost = getBetsToPlace(betslipItems).reduce(
    (total, item) => new Currency(calculateBetCostSingleItem(item)).add(total).value,
    0
  )

  const multiItems = getBetsInMulti(betslipItems)
  if (
    hasTooFewMultiLegs(multiItems) ||
    hasTooManyMultiLegs(multiItems) ||
    multiError?.betErrorType === 'DuplicateBonusBet' ||
    isFatalErrorType(multiError?.betErrorType) ||
    multiError?.betErrorType === 'Unspecified'
  ) {
    return combinedSingleCost
  }
  return combinedSingleCost + calclulateCombinedMultiInvestment(multiItems, multiInvestment)
}

export function calculateEstReturnSingleItem(betslipItem: BetSlipItem) {
  const {
    bettingType,
    isEachWay,
    selection,
    investment: { win, place, bonusBet },
    selectedSuperPickOffer,
  } = betslipItem

  if (isStartingPriceSelection(selection)) return 0

  const { winPrice, placePrice } = selection as FobSelection
  return calculateProjectedReturn(
    bettingType,
    winPrice,
    placePrice || 0,
    win.value,
    place.value || 0,
    isEachWay,
    bonusBet?.value,
    selectedSuperPickOffer
  )
}

function calculateEstReturnForSingles(items: BetSlipItem[]) {
  const singleItems = items.filter(
    item => item.bettingType === 'fixed-odds-racing' || item.bettingType === 'fixed-odds-sports'
  )
  if (singleItems.length === 0) {
    return null
  }
  return singleItems.reduce(
    (total: number, nextItem: BetSlipItem) => total + calculateEstReturnSingleItem(nextItem),
    0
  )
}

export function calculateEstReturn(
  placeableItems: BetSlipItem[],
  placeableMultis: BetSlipItem[],
  multiInvestment: MultiInvestment
): number {
  const singlesEstRtn = calculateEstReturnForSingles(placeableItems) || 0
  const multiAndFormulaEstRtn = calculateMultiProjectedPay(placeableMultis, multiInvestment)
  return new Decimal(singlesEstRtn + multiAndFormulaEstRtn).toDecimalPlaces(2).toNumber()
}

export function calculateBoosts(selectedSuperPickOffer: BetSpecialOffer | null) {
  let winBoost = 0
  let placeBoost = 0
  if (selectedSuperPickOffer) {
    const elementWithPriceIncrease = selectedSuperPickOffer.elements?.find(
      element => element.priceIncrease !== null
    )
    if (elementWithPriceIncrease) {
      const priceIncrease = elementWithPriceIncrease.priceIncrease
      winBoost = priceIncrease?.win ? new Decimal(priceIncrease.win).toNumber() : 0
      placeBoost = priceIncrease?.place ? new Decimal(priceIncrease.place).toNumber() : 0
    }
  }
  return [winBoost, placeBoost]
}

// ==================
// Multi Calculations
// ==================

type FobPriceSelector = (selection: FobSelection) => [number, number | null]

const getFobPrices: FobPriceSelector = (selection: FobSelection) => {
  return [selection.winPrice, selection.placePrice]
}

const getLastSeenFobPrices: FobPriceSelector = selection => {
  return [selection.winPriceLastSeen ?? 0, selection.placePriceLastSeen]
}

const calculateMultiReturnInternal = (
  items: BetSlipItem[],
  { shouldRound, priceSelector }: { shouldRound: boolean; priceSelector: FobPriceSelector } = {
    shouldRound: false,
    priceSelector: getFobPrices,
  }
): number => {
  const multiItems = getBetsInMulti(items)
  const tooManyBets = hasTooManyMultiLegs(multiItems)
  const isValidNumberOfMultiLegs = multiItems.length >= MIN_LEGS_IN_MULTI && !tooManyBets
  if (!isValidNumberOfMultiLegs) {
    return 0
  }
  const multiReturn = new Decimal(
    multiItems.reduce((total: number, item: BetSlipItem) => {
      const [winPrice, placePrice] = priceSelector(item.selection as FobSelection)
      const price = item.multiLegBetType === 'P' ? placePrice || 0 : winPrice || 0
      return new Decimal(total).times(price).toNumber()
    }, 1)
  )

  if (shouldRound) {
    return multiReturn.toDecimalPlaces(2, Decimal.ROUND_DOWN).toNumber()
  }
  return multiReturn.toNumber()
}

export function calculateMultiReturn(
  items: BetSlipItem[],
  { shouldRound }: { shouldRound: boolean } = { shouldRound: false }
): number {
  return calculateMultiReturnInternal(items, { shouldRound, priceSelector: getFobPrices })
}

export function calculateLastSeenMultiReturn(
  items: BetSlipItem[],
  { shouldRound }: { shouldRound: boolean } = { shouldRound: false }
): number {
  return calculateMultiReturnInternal(items, { shouldRound, priceSelector: getLastSeenFobPrices })
}

export function calclulateCombinedMultiInvestment(
  multiItems: BetSlipItem[],
  multiInvestment: MultiInvestment
) {
  if (!isValidMulti(multiInvestment, null, multiItems)) {
    return 0
  }

  let formulaTotal = 0
  const multiItemsCount = multiItems.length

  if (multiItemsCount >= MIN_LEGS_IN_MULTI && multiItemsCount <= MAX_LEGS_FOR_MULTI_FORMULA) {
    for (let i = 1; i <= Math.max(1, Math.min(multiItemsCount - 1, 5)); i++) {
      const multiInvestmentKey = `f${i}` as MultiInvestmentKey
      const legKey =
        `legs${multiItemsCount}` as keyof (typeof MULTI_FORMULA_COMBINATIONS)[typeof multiInvestmentKey]

      formulaTotal += new Decimal(multiInvestment[multiInvestmentKey])
        .times(MULTI_FORMULA_COMBINATIONS[multiInvestmentKey][legKey])
        .toNumber()
    }
  }
  return formulaTotal + multiInvestment.value
}

function generateMultiFormulaCombos(prices: number[], formulaNumber: number) {
  const combos: number[][] = []
  function doGenerateCombinations(offset: number, combo: number[]) {
    if (combo.length === formulaNumber) {
      combos.push(combo)
      return
    }
    for (let i = offset; i < prices.length; i++) {
      doGenerateCombinations(i + 1, combo.concat(prices[i]))
    }
    return
  }
  doGenerateCombinations(0, [])
  return combos
}

export function determineLegTypeFromInvestments(
  winInvestment: number,
  placeInvestment: number
): BetLegType {
  if (winInvestment > 0 && placeInvestment === 0) {
    return 'W'
  }
  if (winInvestment === 0 && placeInvestment > 0) {
    return 'P'
  }
  if (winInvestment > 0 && placeInvestment > 0) {
    return 'WP'
  }
  return 'W'
}

export function calculateMultiFormulaReturn(
  prices: number[],
  formulaNumber: number,
  currentInvestment: number
) {
  return generateMultiFormulaCombos(prices, formulaNumber).reduce((total, combo) => {
    const amount = combo.reduce((prev, cur) => Decimal(prev).times(cur).toNumber(), 1)
    const comboCalc = Decimal(amount)
      .times(100)
      .times(currentInvestment)
      .toDecimalPlaces(0, Decimal.ROUND_DOWN)
    return Decimal(comboCalc).div(100).plus(total).toNumber()
  }, 0)
}

export function calculateMultiProjectedPay(
  multiItems: BetSlipItem[],
  multiInvestment: MultiInvestment
): number {
  const multiItemsCount = multiItems.length
  if (!isValidMulti(multiInvestment, null, multiItems)) {
    return 0
  }

  // Calculate multi projected pay
  const multiProjectedPay =
    multiInvestment.value > 0
      ? new Decimal(calculateMultiReturn(multiItems))
          .times(multiInvestment.value)
          .minus(multiInvestment.bonusBetId ? multiInvestment.value : 0)
      : 0

  // Calculate formula projected pay total
  const prices = multiItems.map(item =>
    item.multiLegBetType === 'W'
      ? (item.selection as FobPropositionSelection).winPrice
      : (item.selection as FobPropositionSelection).placePrice
  ) as number[]

  let formulaProjectedPay = 0

  const isValidLegsForFormula =
    multiItemsCount >= MIN_LEGS_IN_MULTI && multiItemsCount <= MAX_LEGS_FOR_MULTI_FORMULA
  if (isValidLegsForFormula) {
    for (let i = 1; i <= Math.max(1, Math.min(multiItemsCount - 1, 5)); i++) {
      const multiInvestmentKey = `f${i}` as MultiInvestmentKey
      const currentInvestment = multiInvestment[multiInvestmentKey]
      if (currentInvestment > 0) {
        formulaProjectedPay += new Decimal(
          calculateMultiFormulaReturn(prices, i, currentInvestment)
        ).toNumber()
      }
    }
  }

  const finalMultiProjectedPay = new Decimal(multiProjectedPay)
    .toDecimalPlaces(2, Decimal.ROUND_DOWN)
    .toNumber()
  const finalFormulaProjectedPay = new Decimal(formulaProjectedPay).toDecimalPlaces(2).toNumber()

  return finalMultiProjectedPay + finalFormulaProjectedPay
}

function calculateProjectedReturn(
  bettingType: BettingType | null,
  winPrice: number | undefined,
  placePrice: number | undefined,
  winInvestment: number,
  placeInvestment: number,
  isEachWay: boolean,
  bonusBetValue?: number,
  specialOffer?: BetSpecialOffer | null
): number {
  if (isEachWay) {
    const winEstimate = roundEstimateDown((winPrice ?? 0) * winInvestment, 2)
    const placeEstimate = roundEstimateDown((placePrice ?? 0) * winInvestment, 2)
    const projectedReturn = winEstimate + placeEstimate

    return projectedReturn
  }

  switch (bettingType) {
    case 'fixed-odds-racing': {
      let boostReward: number = 0
      if (specialOffer) {
        // const stakeObj: Stake = Stake.normalise({
        //   Win: Decimal(winInvestment || 0),
        //   Place: Decimal(placeInvestment || 0),
        // })
        // TODO: Add correct boost calculations
        boostReward = 0
        // boostReward = new PyosRewardCalculator()
        //   .calculateProjectedBoostReward(specialOffer as IBetSpecialOffer, stakeObj)
        //   .toNumber()
      }

      const winEstimate = roundEstimateDown(winInvestment * (winPrice ?? 0), 2)
      const placeEstimate = roundEstimateDown(placeInvestment * (placePrice ?? 0), 2)
      const projectedReturn = winEstimate + placeEstimate + boostReward - (bonusBetValue ?? 0)

      return roundEstimateDown(projectedReturn, 2)
    }

    case 'fixed-odds-sports': {
      let boostReward: number = 0
      if (specialOffer) {
        // const stakeObj: Stake = Stake.normalise({
        //   Win: Decimal(winInvestment || 0),
        //   Place: Decimal(placeInvestment || 0),
        // })
        // TODO: Add correct boost calculations
        boostReward = 0
        // boostReward = new PyosRewardCalculator()
        //   .calculateProjectedBoostReward(specialOffer as IBetSpecialOffer, stakeObj)
        //   .toNumber()
      }

      const winEstimate = roundEstimateDown(winInvestment * (winPrice ?? 0), 2)
      const projectedReturn = winEstimate + boostReward - (bonusBetValue ?? 0)

      return roundEstimateDown(projectedReturn, 2)
    }

    default: {
      return 0
    }
  }
}

// TODO: Do not use, replace with better
// class Stake {
//   public Win!: decimal.Decimal
//   public Place!: decimal.Decimal

//   private static _toDecimal(value: string | number | decimal.Decimal): decimal.Decimal {
//     return value ? new Decimal(value) : new Decimal(0)
//   }

//   static normalise(stake: Stake): Stake {
//     return stake
//       ? {
//           Win: Stake._toDecimal(stake.Win),
//           Place: Stake._toDecimal(stake.Place),
//         }
//       : {
//           Win: Stake._toDecimal(0),
//           Place: Stake._toDecimal(0),
//         }
//   }
// }
