import React from 'react'
import styled from '@emotion/styled'
import { Accordion } from '@mobi/component-library/Common/V2/Accordion'
import { spacing } from '@mobi/component-library/Theme/Common'
import { useAppSelector } from '@mobi/betslip/Store/hooks'
import type { BetSlipItem } from '@mobi/betslip/types'
import type { BetSlipWorkflowState } from '@mobi/betslip/Store/Workflow'
import { hasInvestment, hasErrors, hasBeenPlaced } from '@mobi/betslip/helpers/state'
import { SingleItem } from './Components/SingleItem'

export const Singles: React.FC<{ items: BetSlipItem[] }> = ({ items }) => {
  const workflowStatus = useAppSelector(state => state.betslip.workflow.currentStatus)

  const filteredItems: BetSlipItem[] = filterItemsForWorkflow(items, workflowStatus)
  if (!filteredItems || filteredItems.length === 0) return null

  return (
    <Accordion
      title={`Singles (${filteredItems.length})`}
      onToggle={() => null}
      shouldStartExpanded={true}
      shouldUseDefaultTheme={false}
    >
      <ListStyled>
        {filteredItems.map(item => (
          <SingleItem key={item.id} item={item} />
        ))}
      </ListStyled>
    </Accordion>
  )
}

// =============
// Local Helpers
// =============

function filterItemsForWorkflow(
  items: BetSlipItem[],
  workflowStatus: BetSlipWorkflowState['currentStatus']
): BetSlipItem[] {
  switch (workflowStatus) {
    case 'proposed': {
      return items.filter(item => hasErrors(item) || hasInvestment(item))
    }

    case 'bets-placed': {
      return items.filter(item => hasBeenPlaced(item))
    }

    default:
      return items
  }
}

// ======
// Styles
// ======

const ListStyled = styled.div({
  display: 'flex',
  flexDirection: 'column',
  gap: spacing.smx1,
  paddingBottom: spacing.smx1,
})
